import React from "react"
import Collapse from "@material-ui/core/Collapse"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import ShowDesktopOnly from "../ShowDesktopOnly"
import ShowMobileOnly from "../ShowMobileOnly"
import Typography from "../Typography"
import HorizontalLine from "../HorizontalLine"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"
import localeData from "../../locale"
import { LinkUnderline } from "../NormalLink"
import { currentLocale } from "../../locale/languages"
import slugify from "../../utils/slugify"

import {
  StudentsIcon,
  StartingPeriodIcon,
  DurationIcon,
} from "../Icons/MetaIcons"

import { DownChevron } from "../Icons"

const { H300Semibold } = Typography

const SmallTitle = styled.p`
  ${H300Semibold}
  margin: 0;
  margin-bottom: 8px;
`

const ContainerNav = styled.div`
  margin-bottom: ${vSpacing.baseX4};
`

const DetailsNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  ${breakpoints.md`
    display: none;
  `};
`

class VacancyDetails extends React.PureComponent {
  state = {
    open: false,
  }

  handleClick() {
    const { open } = this.state
    this.setState({
      open: !open,
    })
  }

  render() {
    const { open } = this.state
    const {
      country,
      flag,
      category,
      startingPeriod,
      vacancyMinimumLengthOfStay,
      numberOfPersons,
      studyFields,
      locale,
    } = this.props
    const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

    const Details = (
      <React.Fragment>
        {country && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>{localeData._rawCountryTitle[locale]}</SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              {flag && (
                <img
                  src={`${flag.asset.url}?w=24`}
                  style={{
                    marginRight: "12px",
                    maxWidth: "24px",
                  }}
                  alt={country || "Country"}
                />
              )}
              <LinkUnderline to={`/${setLocaleInUrl}${slugify(country)}`}>
                {country}
              </LinkUnderline>
            </Vspacer>
          </>
        )}
        {category && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>{localeData._rawCategoryTitle[locale]}</SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              <Vspacer alignItems="center" key={category.id}>
                {category.categoryIcon && (
                  <img
                    src={`${category.categoryIcon.asset.url}?w=24`}
                    style={{
                      marginRight: "12px",
                      maxWidth: "24px",
                    }}
                    alt={category._rawLocaleTitle[locale] || "Categor"}
                  />
                )}
                <p>{category._rawLocaleTitle[locale]}</p>
              </Vspacer>
            </Vspacer>
          </>
        )}
        {startingPeriod && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>{localeData._rawStartingPeriodTitle[locale]}</SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              <StartingPeriodIcon margin="0 16px 0 0" />
              <p>{startingPeriod}</p>
            </Vspacer>
          </>
        )}
        {vacancyMinimumLengthOfStay && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>
                {localeData._rawVacancyMinimumLengthOfStayTitle[locale]}
              </SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              <DurationIcon margin="0 16px 0 0" />
              <p>{vacancyMinimumLengthOfStay._rawLocaleTitle[locale]}</p>
            </Vspacer>
          </>
        )}
        {numberOfPersons && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>{localeData._rawNumberOfPeople[locale]}</SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              <StudentsIcon margin="0 16px 0 0" />
              <p>{numberOfPersons}</p>
            </Vspacer>
          </>
        )}
        {numberOfPersons && (
          <>
            <Vspacer marginBottom={vSpacing.baseX1}>
              <SmallTitle>{localeData._rawStudyFieldsTitle[locale]}</SmallTitle>
            </Vspacer>
            <Vspacer marginBottom={vSpacing.baseX3} alignItems="center">
              <p>{studyFields}</p>
            </Vspacer>
          </>
        )}
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <ShowMobileOnly>
          <ContainerNav>
            <DetailsNav onClick={() => this.handleClick()} active={open}>
              <strong>{localeData._rawProjectDetailsTitle[locale]}</strong>
              <DownChevron width="16px" height="13px" margin="0" flip={open} />
            </DetailsNav>
            <Collapse in={open}>{Details}</Collapse>
            <HorizontalLine />
          </ContainerNav>
        </ShowMobileOnly>

        <ShowDesktopOnly>{Details}</ShowDesktopOnly>
      </React.Fragment>
    )
  }
}

export default VacancyDetails
