import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import VacancyCard from "../VacancyCard"
import { currentLocale } from "../../locale/languages"
import slugify from "../../utils/slugify"
import Vspacer from "../Vspacer"
import vSpacing from "../../styles/vSpacing"
import { LinkUnderline } from "../NormalLink"

import localeData from "../../locale"

const VacancySelection = ({ data, filter, locale }) => {
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""
  let vacancies = data.allSanityVacancy.edges

  if (filter) {
    vacancies = data.allSanityVacancy.edges.filter(
      vacancy =>
        vacancy.node.vacancyCountry._rawLocaleTitle[locale].indexOf(filter) >= 0
    )
  }

  return (
    <>
      {vacancies.length > 0 ? (
        <Vspacer marginBottom={vSpacing.baseX7}>
          <Grid container justify="center" spacing={32}>
            {vacancies.slice(0, 3).map(vacancy => (
              <Grid item xs={12} md={4} key={vacancy.node.id}>
                <Link
                  to={`/${setLocaleInUrl}${slugify(
                    vacancy.node._rawLocaleTitle[locale]
                  )}`}
                >
                  <VacancyCard
                    title={vacancy.node._rawLocaleTitle[locale]}
                    type={vacancy.node.vacancyTypes
                      .map(type => type._rawLocaleTitle[locale])
                      .join(", ")}
                    flag={
                      vacancy.node.vacancyCountry &&
                      vacancy.node.vacancyCountry.flag
                    }
                    country={
                      vacancy.node.vacancyCountry &&
                      vacancy.node.vacancyCountry._rawLocaleTitle[locale]
                    }
                    category={vacancy.node.vacancyCategory}
                    startingPeriod={
                      vacancy.node._rawStartingPeriod &&
                      vacancy.node._rawStartingPeriod[locale]
                    }
                    locale={locale}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Vspacer>
      ) : (
        <Grid item container xs={12} justify="center">
          <p>{localeData._rawNoVacancies[locale]}</p>
        </Grid>
      )}
      <Grid container justify="center">
        <LinkUnderline
          to={`/${setLocaleInUrl}${slugify(
            data.sanityAllVacanciesPage._rawLocaleTitle[locale]
          )}`}
        >
          {localeData._rawViewVacancies[locale]}
        </LinkUnderline>
      </Grid>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query allSanityVacancies {
        allSanityVacancy {
          edges {
            node {
              id
              _rawLocaleTitle
              vacancyTypes {
                _rawLocaleTitle
              }
              _rawStartingPeriod
              vacancyCountry {
                flag {
                  asset {
                    url
                  }
                }
                _rawLocaleTitle
              }
              vacancyCategory {
                id
                _rawLocaleTitle
                categoryIcon {
                  asset {
                    url
                  }
                }
              }
            }
          }
        }
        sanityAllVacanciesPage {
          id
          _rawLocaleTitle
        }
      }
    `}
    render={data => <VacancySelection data={data} {...props} />}
  />
)
