import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import { ShareButton, ShareBlockStandard } from "react-custom-share"
import { FaFacebook, FaLinkedin, FaEnvelope} from 'react-icons/fa';
import blobIconUri from './blobIcon'

const Container = styled.div`
  display: inline-block;
  button {
    color: white;
    height: 42px;
    width: 42px;
    margin-right: 16px;
    border: none;
    background: url("${blobIconUri}");
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    svg {
      height: 16px;
      width: 16px;
    }
  }
`

const SocialShare = ({ url, text, longtext }) => {
  const shareBlockProps = {
    url: `https://www.letsgoafrica.nl${url}`,
    button: ShareButton,
    buttons: [
      { network: "Facebook", icon: FaFacebook },
      { network: "Linkedin", icon: FaLinkedin },
      { network: "Email", icon: FaEnvelope },
    ],
    text,
    longtext,
  }
  return (
    <Container>
      <Grid container alignItems="center">
        <ShareBlockStandard {...shareBlockProps} />
      </Grid>
    </Container>
  )
}

export default SocialShare
