import React from "react"
import styled from "styled-components"
import Typography from "../Typography"
import breakpoints from "../../styles/breakpoints"
import BlockText from "../BlockText"

const { H300Semibold } = Typography

const ContentWithNavContainer = styled.div`
  ${breakpoints.md`
    padding: 0 16px;  
  `};
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  min-height: 56px;
  align-items: center;
  padding-left: 16px;
  ${breakpoints.md`
    padding-left: 0;
    width: auto;
    justify-content: center;
    align-items: center;
    overflow-x: initial;
  `};
`

const NavItem = styled.div`
  ${H300Semibold}
  padding: 0 16px 0 0;
  color: ${props => props.active && props.theme.colors.orange800};
  transition: color ${props => props.theme.transitions.hover};
  flex: none;
  ${breakpoints.md`
    padding: 14px 16px;
  `};
  &:hover {
    cursor: pointer;
    color: ${props =>
      props.active
        ? props.theme.colors.orange800
        : props.theme.colors.black400};
  }
`

const Body = styled.div`
  padding: 0 16px;
  ${breakpoints.md`
    padding: 0 0 14px 0;
  `};
`

class ContentWithNav extends React.PureComponent {
  state = {
    body: null,
    active: null,
  }

  constructor(props) {
    super(props)
    const { key, body } = props.data[0]
    this.state = {
      active: key,
      body,
    }
  }

  handleClick(body, active) {
    this.setState({
      body,
      active,
    })
  }

  render() {
    const { body, active } = this.state
    const { data, locale } = this.props
    if (data && body) {
      return (
        <ContentWithNavContainer>
          <Container>
            {data.map(item => {
              if (item.body) {
                if (active === item.key) {
                  return (
                    <NavItem
                      key={item.key}
                      onClick={() => this.handleClick(item.body, item.key)}
                      active
                    >
                      {item.nav[locale]}
                    </NavItem>
                  )
                }
                return (
                  <NavItem
                    key={item.key}
                    onClick={() => this.handleClick(item.body, item.key)}
                  >
                    {item.nav[locale]}
                  </NavItem>
                )
              }
            })}
          </Container>
          <Body>
            <BlockText blocks={body[locale]} />
          </Body>
        </ContentWithNavContainer>
      )
    }
    return null
  }
}

export default ContentWithNav
